import React, { useEffect, useState, useRef } from 'react';
import SEOHead from '../../components/SEOHead';
import SignupModal from '../../components/SignUpModal/SignUpModal';


const AboutPage = () => {
  const [isPodcastScrollHovering, setIsPodcastScrollHovering] = useState(false);
  const podcastScrollRef = useRef(null);
  const [podcastScrollWidth, setPodcastScrollWidth] = useState(0);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(new Set());
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const founderPhotos = [
    "/images/shihan-tom-callahan-1.jpg",
    "/images/shihan-tom-callahan-2.jpg",
    "/images/shihan-tom-callahan-3.jpg",
    "/images/shihan-tom-callahan-4.jpg"
  ];

  const timelineData = [
    {
      year: "1972",
      content: "Shihan Tom Callahan begins his martial arts journey in Uechi-Ryu Karate"
    },
    {
      year: "1990s",
      content: "Return to martial arts, focusing on Kyokushinkai Karate"
    },
    {
      year: "2008",
      content: "Transition from IKO-1 to Kyokushin-Kan Organization"
    },
    {
      year: "2009",
      content: "Founding of World Budo Arts, expanding the philosophy of Budo globally"
    },
    {
      year: "2023",
      content: "Promoted to Rokudan in Kyokushin-Kan from Kaicho Hatsuo Royama"
    },
    {
      year: "Present",
      content: "Expanding World Budo Arts globally and sharing a lifetime of accumulated knowledge"
    }
  ];

  const podcasts = [
    { id: 1, url: 'https://youtu.be/d7-4TTZcBOk' },
    { id: 2, url: 'https://youtu.be/I-BaQlK0Z38' },
    { id: 3, url: 'https://youtu.be/27ofLxq_-IQ' },
    { id: 4, url: 'https://youtu.be/XZP0P8Hu52U' },
    { id: 5, url: 'https://youtu.be/n4MVYHm-UrQ' },
    { id: 6, url: 'https://youtu.be/yDIQndcBjN8' },
    { id: 7, url: 'https://youtu.be/g-0Ylx3j0uU' },
    { id: 8, url: 'https://youtu.be/F_jKOftZ484' },
    { id: 9, url: 'https://youtu.be/7RPZhFPjSTc' }
  ];

  const affiliatePartners = [
    { id: 1, image: '/images/tom-with-kaicho.jpg', title: 'Shihan Tom & Kaicho Royama', description: 'A meeting of minds: Shihan Tom Callahan with Kaicho Hatsuo Royama' },
    { id: 2, image: '/images/kyokushin-kan-logo.png', title: 'Kyokushin-Kan Logo', description: 'Official logo of the Kyokushin-Kan Karate organization' },
    { id: 3, image: '/images/kaicho-black-belt-magazine.jpg', title: 'Kaicho on Black Belt Magazine', description: 'Kaicho Hatsuo Royama featured on the cover of Black Belt Magazine' }
  ];

  const handleOpenSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisibleItems(prev => new Set([...prev, entry.target.dataset.index]));
        }
      });
    }, observerOptions);

    const timelineItems = document.querySelectorAll('[data-timeline-item]');
    timelineItems.forEach(item => observer.observe(item));

    return () => {
      timelineItems.forEach(item => observer.unobserve(item));
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % founderPhotos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [founderPhotos.length]);

  useEffect(() => {
    const podcastContainer = podcastScrollRef.current;

    const updatePodcastScrollWidth = () => {
      if (podcastContainer) {
        setPodcastScrollWidth(podcastContainer.scrollWidth / 2);
      }
    };

    updatePodcastScrollWidth();
    window.addEventListener("resize", updatePodcastScrollWidth);

    let animationId;
    const scrollPodcasts = () => {
      if (!isPodcastScrollHovering && podcastContainer) {
        podcastContainer.scrollLeft += 1;
        if (podcastContainer.scrollLeft >= podcastScrollWidth) {
          podcastContainer.scrollLeft = 0;
        }
      }
      animationId = requestAnimationFrame(scrollPodcasts);
    };

    animationId = requestAnimationFrame(scrollPodcasts);

    return () => {
      window.removeEventListener("resize", updatePodcastScrollWidth);
      cancelAnimationFrame(animationId);
    };
  }, [isPodcastScrollHovering, podcastScrollWidth]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  const getYouTubeVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  return (
    <div className="font-main w-full" id="main-content">
      <SEOHead
        title="About World Budo Arts | Shihan Tom Callahan"
        description="Learn about Shihan Tom Callahan and World Budo Arts' philosophy of martial arts training. Discover our commitment to authentic martial arts instruction."
        path="/about"
      />
      <section className="relative w-full py-20 bg-primary-black text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5 w-full">
          <h1 className="font-display text-[2.5rem] mb-2 text-primary-yellow">About World Budo Arts</h1>
          <p className="text-[1.2rem] italic">Understanding the Way of Budo</p>
        </div>
      </section>

      <section className="w-full py-20">
        <div className="max-w-7xl mx-auto px-5">
          <div className="flex flex-col md:flex-row items-start gap-10">
            <div className="flex-none w-full md:w-[300px] h-[400px] relative mb-5 md:mb-0">
              <div className="relative w-full h-full">
                {founderPhotos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    alt={`Shihan Tom Callahan ${index + 1}`}
                    className={`absolute top-0 left-0 w-full h-full object-cover rounded-lg shadow-md
                      transition-opacity duration-1000 ease-in-out
                      ${index === currentPhotoIndex ? 'opacity-100 z-20' : 'opacity-0 z-10'}`}
                  />
                ))}
              </div>
            </div>

            <div className="flex-1 text-center md:text-left">
              <h2 className="font-display text-[2rem] text-primary-red mb-2.5">Kyoshi Shihan Tom Callahan</h2>
              <h3 className="font-display text-[1.2rem] text-primary-black mb-5">6th Dan, Founder of World Budo Arts</h3>
              <div className="space-y-4 text-left">
                <p className="text-base leading-relaxed">
                  Shihan Callahan's martial arts journey began in 1972 in Uechi-Ryu Karate under Al Wharton and Mattson Academy.
                  In addition to his Kyokushin Karate 6th dan and Kyoshi Shihan title under Kaicho Hatsuo Royama, he has supplemented his skills with various styles including Krav Maga under Roy Elghanayan and Jukkendo under Nori Bunasawa.
                </p>
                <p>
                  As the Vice-Chairman "All-Americas" and International Committee Secretary for Kyokushin-Kan,
                  Shihan Callahan continues to expand his knowledge and skills, passing them on to the next generation
                  through World Budo Arts.
                </p>
                <p>
                  Throughout his career, Shihan Callahan has competed in numerous tournaments, earning accolades and
                  respect from peers worldwide. His dedication to the martial arts extends beyond physical training,
                  emphasizing the importance of mental discipline and spiritual growth.
                </p>
                <p>
                  In founding World Budo Arts, Shihan Callahan aims to create a global community of martial artists
                  united by the principles of Budo. His vision is to preserve traditional martial arts while adapting
                  to the needs of modern practitioners, fostering an environment where students can grow both as
                  martial artists and as individuals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-20 bg-primary-white">
        <div className="max-w-7xl mx-auto px-5">
          <h2 className="font-display text-center text-[2rem] text-primary-red mb-12">Our Journey</h2>
          <div className="relative max-w-[1000px] mx-auto">
            {/* Timeline center line - make it thicker and more prominent */}
            <div className="absolute left-[20px] md:left-1/2 top-0 bottom-0 w-1.5 bg-primary-red 
        transform md:-translate-x-1/2 z-10"></div>

            {timelineData.map((item, index) => (
              <div
                key={index}
                data-timeline-item
                data-index={index}
                className={`relative w-[calc(100%-40px)] md:w-[calc(50%-40px)] mb-16
      transition-all duration-700 ease-in-out
      ${index % 2 === 0 ?
                    'ml-[45px] md:ml-0 md:pr-10' :
                    'ml-[45px] md:ml-[50%] md:pl-10'}
      ${visibleItems.has(index.toString()) ?
                    'opacity-100 translate-x-0' :
                    `opacity-0 ${index % 2 === 0 ? '-translate-x-12' : 'translate-x-12'}`}`}
              >
                {/* Timeline dot */}
                <div className={`absolute w-6 h-6 bg-primary-yellow border-4 border-primary-red rounded-full
  top-4 z-20 ${index % 2 === 0 ?
                    'left-[-32px] md:right-[-52px] md:left-auto' :
                    'left-[-12px]'}`}
                ></div>

                {/* Content box */}
                <div className="bg-primary-white p-6 rounded-lg shadow-[0_4px_10px_rgba(0,0,0,0.1)]
      hover:shadow-[0_6px_15px_rgba(0,0,0,0.15)] transition-all duration-300">
                  <h3 className="text-[1.4rem] font-bold text-primary-red mb-3">{item.year}</h3>
                  <p className="text-[1.1rem] leading-relaxed text-gray-700">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="w-full py-20 bg-primary-black text-primary-white overflow-hidden">
        <div className="max-w-7xl mx-auto px-5">
          <h2 className="font-display text-[2rem] text-primary-red mb-8 text-center">Our Podcasts</h2>
          <div
            ref={podcastScrollRef}
            className="overflow-x-auto whitespace-nowrap py-5 scrollbar-none touch-pan-x"
            onMouseEnter={() => setIsPodcastScrollHovering(true)}
            onMouseLeave={() => setIsPodcastScrollHovering(false)}
          >
            <div className="inline-flex pb-5">
              {podcasts.concat(podcasts).map((podcast, index) => (
                <div key={`${podcast.id}-${index}`} className="flex-none w-[300px] mx-4">
                  <a href={podcast.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Link to external YouTube podcast"
                    className="block transition-transform duration-300 hover:scale-105"
                  >
                    <div className="relative w-full pt-[56.25%] rounded-lg shadow-md overflow-hidden
                            group hover:outline hover:outline-3 hover:outline-primary-red">
                      <img
                        src={`https://img.youtube.com/vi/${getYouTubeVideoId(podcast.url)}/0.jpg`}
                        alt={`Podcast ${podcast.id}`}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 
                              transition-opacity duration-300 flex items-center justify-center">
                        <div className="w-0 h-0 border-t-[20px] border-b-[20px] border-l-[40px]
                              border-t-transparent border-b-transparent border-l-primary-red"></div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-20 bg-[#f9f9f9] text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h2 className="font-display text-[2rem] text-primary-red mb-5">Our Affiliations</h2>
          <p className="text-base leading-relaxed max-w-[800px] mx-auto mb-8">
            World Budo Arts is proud to be affiliated with Kaicho Hatsuo Royama and Kancho Hiroto Okazaki's
            Kyokushin-Kan Karate do Renmei, based in Saitama, Japan.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {affiliatePartners.map((partner) => (
              <div key={partner.id} className="relative aspect-square rounded-lg overflow-hidden shadow-lg">
                <img
                  src={partner.image}
                  alt={partner.title}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="w-full py-20 bg-primary-red text-primary-white text-center">
        <div className="max-w-7xl mx-auto px-5">
          <h2 className="font-display text-[2rem] mb-5">Join Our Community</h2>
          <p className="text-[1.2rem] mb-8 max-w-[600px] mx-auto">
            Experience the transformative power of Budo and receive a free lesson from Shihan Tom.
          </p>
          <button
            onClick={handleOpenSignupModal}
            className="inline-block px-8 py-4 text-[1.2rem] bg-primary-yellow text-primary-black
                 font-bold rounded hover:bg-primary-white hover:text-primary-red
                 transition-all duration-300 hover:scale-105 hover:shadow-lg"
          >
            Get Free Lesson
          </button>
        </div>
      </section>
      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
      />
    </div>
  );
};

export default AboutPage;