import React from 'react';

const KyokushinEvents = () => {
  const events = [
    { activity: "Kagami Biraki (New Year Training)", date: "January 13", location: "Honbu, Japan" },
    { activity: "Online Seminar", date: "February 23", location: "Zoom" },
    { activity: "International Seminar", date: "April 5,6", location: "Krakow, Poland" },
    { activity: "Nations Cup", date: "March 8-10", location: "Moscow, Russia" },
    { activity: "International All Japan Tournament", date: "April 26,27", location: "Toda Sport Centre, Japan" },
    { activity: "International Instructors Seminar and Promotion Test", date: "April 28,29", location: "Toda Sport Centre, Japan" },
    { activity: "Trimmiser Cup", date: "June 14", location: "Chur, Switzerland" },
    { activity: "Summer Camp", date: "July 1-7", location: "Primorsko, Bulgaria" },
    { activity: "Summer Camp", date: "July", location: "Japan" },
    { activity: "World Championship preparation Int. Training Camp", date: "August 1-3", location: "Seoul, Korea" },
    { activity: "2025 Kyokushin-Kan Open World Championships", date: "November 22,23", location: "Tokyo, Japan (National Yoyogi Olympic Stadium 2nd Gymnasium" },
    { activity: "International Seminar and Promotion Test", date: "November 24", location: "Tokyo, Japan" },
    { activity: "Online Seminar", date: "December", location: "Zoom" }
  ];

  return (
    <div className="w-full py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-primary-red text-center mb-8" id="events-table-title">
          All Upcoming Events for Kyokushin-Kan
        </h2>
        
        <div className="overflow-x-auto shadow-lg rounded-lg">
          <table 
            className="min-w-full bg-white" 
            aria-labelledby="events-table-title"
          >
            <thead>
              <tr className="bg-primary-black text-white">
                <th scope="col" className="px-6 py-4 text-left">Activity</th>
                <th scope="col" className="px-6 py-4 text-left">DATE</th>
                <th scope="col" className="px-6 py-4 text-left">Location</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, index) => (
                <tr 
                  key={index} 
                  className={`
                    ${index % 2 === 0 ? 'bg-orange-100' : 'bg-orange-50'}
                    hover:bg-orange-200 transition-colors duration-200
                  `}
                >
                  <td className="px-6 py-4">{event.activity}</td>
                  <td className="px-6 py-4">{event.date}</td>
                  <td className="px-6 py-4">{event.location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KyokushinEvents;